import {
    Container,
    Header,
    StatusIndicator,
    KeyValuePairs,
    Table,
    Link as CloudScapeLink,
    FormField,
    Select,
    Button,
} from '@amzn/awsui-components-react-v3';
import { useActivityGroupDeliveryWeekOptions } from '../../hooks/useActivityGroupDeliveryWeekOptions';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Link } from 'react-router-dom';
import { V_ILT_TYPE } from '../../../../../constants';
import { DeliverySession } from '../../../../../interfaces/activity';
import { ActivityStatus } from '../../../Common/Common';
import { getStatusIndicatorType } from '../../utils/activity-group-utils';
import { useActivityGroup } from '../../hooks/hooks';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const ActivityGroupDeliveryDetails = () => {
    const activityGroup = useActivityGroup();
    const { status, child_activities, name } = activityGroup || {};

    if (!activityGroup || !child_activities || child_activities.length === 0) {
        return null;
    }

    const activity = child_activities[0];

    if (!activity || !activity.pk) {
        return null;
    }

    const { delivery_sessions: deliverySessions = [] } = activity;
    const initialSession = deliverySessions[0];
    const finalSession = deliverySessions[deliverySessions.length - 1];

    const { weekOptionsForDates, selectedWeek, handleWeekChange } =
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useActivityGroupDeliveryWeekOptions({
            start: initialSession?.start_timestamp,
            end: finalSession?.end_timestamp,
            timezone: activity.delivery_timezone,
            includeAllOption: false,
        });

    const formatDate = (timestamp) => {
        return new Date(timestamp * 1000).toLocaleDateString('en-US', {
            month: 'long',
            day: '2-digit',
            year: 'numeric',
        });
    };

    const formatTime = (startTimestamp, endTimestamp) => {
        const startTime = new Date(startTimestamp * 1000)
            .toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
            })
            .toLowerCase();
        const endTime = new Date(endTimestamp * 1000)
            .toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
            })
            .toLowerCase();
        return `${startTime} - ${endTime}`;
    };

    const getFilteredSessions = () => {
        if (!activity.delivery_sessions) {
            return [];
        }

        const weekStart = dayjs(selectedWeek.value);
        const weekEnd = weekStart.add(1, 'week');

        return activity.delivery_sessions.filter((session) => {
            const sessionDate = dayjs.unix(session.start_timestamp);
            return (
                sessionDate.isAfter(weekStart) && sessionDate.isBefore(weekEnd)
            );
        });
    };

    const activityDetailsItems = [
        {
            label: 'Activity Name',
            value: (
                <CloudScapeLink external href={`/activities/${activity.pk}`}>
                    {activity.activity_name}
                </CloudScapeLink>
            ),
        },
        {
            label: 'Activity status',
            value: (
                <StatusIndicator
                    type={getStatusIndicatorType(activity.activity_status)}
                >
                    {activity.activity_status}
                </StatusIndicator>
            ),
        },
        {
            label: 'Program',
            value: activity.program,
        },
        {
            label: 'Delivery Region',
            value: activity.delivery_region,
        },
        {
            label: 'Location',
            value: `${activity.delivery_city || ''}, ${
                activity.delivery_state || ''
            }, ${activity.delivery_country || ''}`,
        },
        {
            label: 'Time Zone',
            value: activity.delivery_timezone,
        },
        {
            label: 'Modality',
            value: activity.activity_modality,
        },
    ];

    const sessionTableColumns = [
        {
            id: 'session_type',
            header: 'Session Type',
            cell: (item) => item.delivery_session_type,
            sortingField: 'delivery_session_type',
        },
        {
            id: 'date',
            header: 'Date',
            cell: (item) => formatDate(item.start_timestamp),
            sortingField: 'start_timestamp',
        },
        {
            id: 'time',
            header: 'Time',
            cell: (item) =>
                formatTime(item.start_timestamp, item.end_timestamp),
            sortingField: 'start_timestamp',
        },
        {
            id: 'meetingType',
            header: 'Meeting Type',
            cell: (item) => item.v_ilt_info?.type || '-',
            sortingField: 'v_ilt_info.type',
        },
        {
            id: 'meetingId”',
            header: 'Meeting ID',
            cell: ({ v_ilt_info: { type, streamyard_url, url } }) =>
                type === V_ILT_TYPE.PVILT
                    ? (streamyard_url ?? '-')
                    : (url ?? '-'),
            sortingField: 'v_ilt_info.type',
        },
        {
            id: 'name',
            header: 'Instructor(s)',
            cell: (item) => {
                return item.instructors.map((instructor, index, array) => {
                    const separator = index === array.length - 1 ? '' : ', ';
                    return (
                        <>
                            <Link to={`/instructors/${instructor.pk}`}>
                                {instructor.name}
                            </Link>
                            {separator}
                        </>
                    );
                });
            },
        },
    ];

    return (
        <div>
            <Container
                header={
                    <Header
                        actions={
                            <Link
                                to={`/activities/group/${name}/edit-delivery-details`}
                            >
                                <Button
                                    disabled={
                                        status === ActivityStatus.Canceled
                                    }
                                    disabledReason="Cannot edit a canceled activity group."
                                    data-testid="EditButtonActivityGroup"
                                >
                                    Edit
                                </Button>
                            </Link>
                        }
                        variant="h2"
                    >
                        Delivery details
                    </Header>
                }
                variant="stacked"
            >
                <KeyValuePairs columns={4} items={activityDetailsItems} />
            </Container>

            <FormField stretch={true}>
                <Select
                    options={weekOptionsForDates}
                    selectedOption={selectedWeek}
                    onChange={({ detail }) =>
                        handleWeekChange(detail.selectedOption)
                    }
                />
            </FormField>

            <Table
                sortingDisabled
                variant="stacked"
                header={
                    <Header
                        variant="h2"
                        counter={`(${getFilteredSessions().length})`}
                    >
                        Sessions
                    </Header>
                }
                columnDefinitions={sessionTableColumns}
                items={getFilteredSessions()}
            />
        </div>
    );
};

export default ActivityGroupDeliveryDetails;

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import {
    Alert,
    Button,
    Header,
    SpaceBetween,
    Spinner,
    StatusIndicator,
} from '@amzn/awsui-components-react-v3';
import { ACTIVITY_PATH } from '../../../../constants/path';
import { fetchActivityGroupByName } from '../../../../store/slices/activityGroupSlice';
import { activityGroupSlice } from '../../../../store/slices/activityGroupSlice';
import { useActivityGroup } from '../hooks/hooks';
import { formatString } from '../../../../../common/utils/formatString';
import { ActivityStatus } from '../../Common/Common';
import ActivityGroupDetails from '../components/ActivityGroupDetails/ActivityGroupDetails';
import ActivityGroupDeliveryDetails from '../components/ActivityGroupDeliveryDetails/ActivityGroupDeliveryDetails';
import { use404Handler } from '../hooks/use404Handler';

export const ActivityGroupDetailsPage = () => {
    const dispatch = useDispatch();
    const {
        params: { name },
    } = useRouteMatch<{ name: string }>(ACTIVITY_PATH.GROUP_VIEW);
    const activityGroup = useActivityGroup();
    const { error, is_loading } = activityGroup;

    const notFoundAlert = use404Handler({
        isNotFound: !is_loading && !!error,
        redirectPath: ACTIVITY_PATH.GROUP_LIST,
        title: error && error.title,
        message: error && error.message,
        linkText: error && error.linkText,
    });

    useEffect(() => {
        dispatch(activityGroupSlice.actions.setLoading(true));
        dispatch(fetchActivityGroupByName(name));

        return () => {
            dispatch(activityGroupSlice.actions.clearActivityGroup());
        };
    }, []);

    const isAddDeliveryButtonVisible =
        activityGroup.child_activities.length === 0 &&
        activityGroup.status !== ActivityStatus.Canceled;

    if (notFoundAlert) {
        return notFoundAlert;
    }

    return is_loading ? (
        <StatusIndicator type="loading">
            Loading Activity Group...
        </StatusIndicator>
    ) : (
        <>
            <Header
                variant="h1"
                actions={
                    isAddDeliveryButtonVisible ? (
                        <>
                            <Link
                                to={`/activities/group/${activityGroup.name}/edit-delivery-details`}
                            >
                                <Button data-testid="EditGroupButton">
                                    Add delivery details
                                </Button>
                            </Link>
                        </>
                    ) : null
                }
            >
                <span data-testid="ActivityTitle">
                    {formatString(activityGroup.name)}
                </span>
            </Header>
            <ActivityGroupDetails />
            <ActivityGroupDeliveryDetails />
        </>
    );
};

export default ActivityGroupDetailsPage;
